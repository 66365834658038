import {mapMutations, mapActions, mapGetters} from "vuex";
import {dynamicHead} from "../../../../mixins/dynamic-head";

//sections


export default {
    name: "article",
    components: {},
    mixins: [dynamicHead],
    data() {
        return {
            crumbs: [
                {src: 'home', title: this.$t('home.title'), slug: ''},
                {src: 'articles', title: this.$t('blog.title'), slug: 'articles'}
            ],
        }
    },
    beforeUpdate() {
        this.getCrumbs()
    },
    created() {
        let slug = this.$route.params.slug
        this.getContent(slug).then(() => {
            this.setPageItem(this.articlePage)
        })
        this.getCrumbs()
        this.setRequestFlag(true);
    },
    mounted() {
    },
    computed: {
        ...mapGetters({
            articlePage: 'articles/articlePage',
            globalRequestsFlag: 'system/globalRequestsFlag'
        })
    },

    methods: {
        ...mapActions({
            getContent: 'articles/GET_ARTICLE_PAGE'
        }),
        ...mapMutations({
            setRequestFlag: 'system/SET_REQUESTS_FLAG',
        }),
        getCrumbs() {
            let slug = this.$route.params.slug
            let duplicate = this.crumbs.find(crumb => crumb.slug === slug)
            if (!duplicate) {
                if (this.crumbs.length === 3) {
                    this.crumbs.splice(2, 1)
                }
                this.crumbs.push(
                    {
                        title: this.articlePage.title,
                        src: 'article',
                        slug: this.articlePage.slug
                    }
                )
            }
        },
        articlesNavigation(position) {
            if (position === 'prev') {
                this.$router.push({name: 'article', params: {slug: this.articlePage.prev}}).catch(() => {
                });
                this.getContent(this.articlePage.prev)
            } else {
                this.$router.push({name: 'article', params: {slug: this.articlePage.next}}).catch(() => {
                });
                this.getContent(this.articlePage.next)
            }
        }
    }
}
